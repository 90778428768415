/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, {
  useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Step from '@material-ui/core/Step';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Theme from '@material-ui/core/styles/MuiThemeProvider';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles } from '@material-ui/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { FileObject } from 'material-ui-dropzone';
import colorContext from '../../Context/colorContext';
import {
  Conclusion,
  Description,
  Identification,
  Localization,
} from './Steps';
import Breadcrumb from './Breadcrumb';
import Registry from './Registry';
import refContext from '../../Context/refContext';
import { getService } from '../../services/servico';
import { SearchI } from '../SearchService';
import Terms from './Terms';

export type SlugManifestation =
  | 'elogio'
  | 'reclamacao'
  | 'denuncia'
  | 'sugestao';
export interface ManifestationType {
  id: number;
  titulo: string;
  slug: SlugManifestation;
}
export interface DemandData {
  conteudo: string;
  tipo_identificacao: 'Identificado' | 'Sigiloso';
  servico_slug: string;
  servico_titulo: string;
  servico_descricao: string;
  canal_entrada: string;
  orgao: string;
  bairro: string;
  cep: string;
  cidade: string;
  logradouro: string;
  estado: string;
  numero: string;
  complemento: string;
  arquivos?: FileObject[];
  codigo_anonimo?: string;
  email_sigiloso?: string;
  cpf_sigiloso?: string;
  nome_sigiloso?: string;
  tipo_tempo?: string;
  tempo_total?: string;
  ponto_referencia: string;
  orgao_name: string;
  setor_id: number;
  aceite?: number;
}

interface DemandCodesProps {
  code?: string;
  protocol: string;
}

const stepsInfo = [
  {
    title: 'Identificação',
    icon: ContactMailIcon,
  },
  {
    title: 'Termo',
    icon: DescriptionIcon,
  },
  {
    title: 'Local do fato',
    icon: LocationOnIcon,
  },
  {
    title: 'Descrição',
    icon: DescriptionIcon,
  },
  {
    title: 'Conclusão',
    icon: CheckCircleIcon,
  },
];

export default function Demand(): JSX.Element {
  const { slug } = useParams<{ slug: string }>();
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      minHeight: 500,
      backgroundColor: colors.colorBackground,
      display: 'flex',
      flexDirection: 'column',
    },
    boxTitle: {
      width: '100%',
      backgroundColor: colors.accentColorBackground,
      minHeight: 276,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 40,
      marginBottom: 56,
      [theme.breakpoints.down(660)]: {
        marginBottom: 24,
        minHeight: 208,
        gap: 32,
      },
    },
    title: {
      fontSize: 36,
      fontWeight: 800,
      color: colors.textAccentColor,
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(20),
      },
    },
    subtitle: {
      fontSize: 24,
      fontWeight: 400,
      color: colors.textAccentColor,
    },
    iconTitle: {
      width: 42,
      height: 56,
      color: colors.textAccentColor,
      [theme.breakpoints.down(660)]: {
        width: 32,
        height: 32,
      },
    },
    boxStepper: {
      maxWidth: 920,
      width: '100%',
      margin: '0 auto',
    },
    stepper: {
      backgroundColor: '#F6F8FC',
      padding: '8px 10px',
      borderRadius: 80,
      justifyContent: 'space-between',
      '& .MuiStepConnector-root': {
        width: 5,
      },
    },
    boxIcon: {
      width: 48,
      height: 48,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.accentColorBackground,
    },
    stepIcon: {
      fontSize: 24,
    },
    stepCount: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
      color: colors.textBody,
    },
    stepTitle: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
    },
    stepMobile: {
      height: 180,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
      backgroundColor: '#F6F8FC',
      [theme.breakpoints.down(660)]: {
        height: 130,
      },
    },
    stepIconMobile: {
      fontSize: 30,
      color: colors.colorBackground,
      [theme.breakpoints.down(660)]: {
        fontSize: 18,
      },
    },
    boxIconMobile: {
      width: 60,
      height: 60,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.accentColorBackground,
      [theme.breakpoints.down(660)]: {
        width: 40,
        height: 40,
      },
    },
    stepCountMobile: {
      fontSize: theme.typography.pxToRem(14),
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    stepTitleMobile: {
      fontSize: theme.typography.pxToRem(20),
      [theme.breakpoints.down(660)]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
  }));
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [service, setService] = useState<SearchI>();
  const [demandaData, setDemandaData] = useState<DemandData>();
  const [demandCodes, setDemandCodes] = useState<DemandCodesProps>();
  const matches = useMediaQuery('(min-width:850px)');
  const matchesMobile = useMediaQuery('(max-width: 660px)');
  const { refRegistryType } = useContext(refContext);

  const getServiceWithSlug = async () => {
    const { data } = await getService(slug);
    const currentService = data.results[0];
    setService(currentService);
    setDemandaData({
      orgao: currentService.orgao_slug,
      servico_descricao: currentService.descricao,
      servico_titulo: currentService.titulo,
      servico_slug: currentService.slug,
      orgao_name: currentService.orgao_nome,
      setor_id: currentService.setor_id,
      tempo_total: currentService.tempo_total,
      tipo_tempo: currentService.tipo_tempo?.split(' ')[0],
      ...demandaData,
    });
  };

  useEffect(() => {
    let interval = null;
    if (matchesMobile) {
      interval = setInterval(() => {
        const element = document.getElementById('chat-window-iframe');
        const userWayElement = document.getElementById('userwayAccessibilityIcon');

        if (element && userWayElement) {
          element.style.visibility = 'hidden';
          userWayElement.style.display = 'none';
          clearInterval(interval);
        }
      }, 100);
    }
    else {
      interval = setInterval(() => {
        const element = document.getElementById('chat-window-iframe');
        const userWayElement = document.getElementById('userwayAccessibilityIcon');
        if (element && userWayElement) {
          element.style.visibility = 'visible';
          userWayElement.style.display = 'block';
          clearInterval(interval);
        }
      }, 100);
    }
    return () => clearInterval(interval);
}, [matchesMobile]);

  const iconCustomized = (IconStepper: any, step: StepIconProps) => {
    const { active, completed } = step;
    return (
      <div
        className={classes.boxIcon}
        style={{
          backgroundColor:
            active || completed
              ? colors.accentColorBackground
              : colors.colorBackground,
        }}
      >
        <IconStepper
          className={classes.stepIcon}
          style={{
            color: active || completed ? colors.colorBackground : '#DAE1EF',
          }}
        />
      </div>
    );
  };

  const controlStep = (action: 'prev' | 'next') => {
    // eslint-disable-next-line default-case
    switch (action) {
      case 'prev':
        setActiveStep((state) => state - 1);
        break;
      case 'next':
        setActiveStep((state) => state + 1);
        break;
    }
  };

  const handleChangeDemandData = (newData: DemandData) => {
    const data = { ...demandaData, ...newData };
    setDemandaData(data);
  };

  const handleChangeDemandCode = (code: string, protocol: string) => {
    setDemandCodes({ code, protocol });
  };

  useEffect(() => {
    getServiceWithSlug();
  }, []);

  useEffect(() => {
    if (refRegistryType) {
      refRegistryType.current.scrollIntoView();
    }
  }, [activeStep, refRegistryType]);

  return (
    <div className={classes.main} ref={refRegistryType}>
      {service ? (
        <>
          <Breadcrumb slug={service.titulo} />
          <Box className={classes.boxTitle}>
            <Typography className={classes.title}>
              {service?.titulo}
            </Typography>
            <Typography className={classes.subtitle}>
              {service?.orgao_nome}
            </Typography>
          </Box>
          {activeStep <= 4 ? (
            <>
              <Box className={classes.boxStepper}>
                {matches ? (
                  <Stepper
                    activeStep={activeStep}
                    classes={{ root: classes.stepper }}
                    connector={<></>}
                  >
                    {stepsInfo.map((step, index) => (
                      <Step key={`key-${step.title}`}>
                        <StepLabel
                          StepIconComponent={(stepIcon) => iconCustomized(step.icon, stepIcon)}
                        >
                          <Box>
                            {index <= activeStep && (
                              <span className={classes.stepCount}>
                                Passo
                                {' '}
                                {index + 1}
                                /
                                {stepsInfo.length}
                              </span>
                            )}
                            <Typography
                              className={classes.stepTitle}
                              style={{
                                color:
                                  index <= activeStep
                                    ? colors.textBody
                                    : colors.subTitleColor,
                              }}
                            >
                              {step.title}
                            </Typography>
                          </Box>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                ) : (
                  <Box className={classes.stepMobile}>
                    {stepsInfo.map((step, index) => (
                      <>
                        {index === activeStep && (
                          <>
                            <div className={classes.boxIconMobile}>
                              <Icon
                                component={step.icon}
                                className={classes.stepIconMobile}
                              />
                            </div>
                            <span
                              className={`${classes.stepCount} ${classes.stepCountMobile}`}
                            >
                              Passo
                              {' '}
                              {index + 1}
                              /
                              {stepsInfo.length}
                            </span>
                            <Typography
                              className={`${classes.stepTitle} ${classes.stepTitleMobile}`}
                              style={{
                                color:
                                  index <= activeStep
                                    ? colors.textBody
                                    : colors.subTitleColor,
                              }}
                            >
                              {step.title}
                            </Typography>
                          </>
                        )}
                      </>
                    ))}
                  </Box>
                )}
                <Box>
                  {activeStep === 0 && (
                    <Identification
                      controlStep={controlStep}
                      changeDemandData={handleChangeDemandData}
                      slug={slug}
                      demandaData={demandaData}
                    />
                  )}
                  {activeStep === 1 && (
                  <Terms
                    controlStep={controlStep}
                    changeDemandData={handleChangeDemandData}
                    demandData={demandaData}
                  />
                  )}
                  {activeStep === 2 && (
                    <Localization
                      controlStep={controlStep}
                      changeDemandData={handleChangeDemandData}
                      demandData={demandaData}
                    />
                  )}
                  {activeStep === 3 && (
                    <Description
                      controlStep={controlStep}
                      changeDemandData={handleChangeDemandData}
                      demandData={demandaData}
                      descriptionDefault={service?.descricao_fluxo_padrao}
                    />
                  )}
                  {activeStep === 4 && (
                    <Conclusion
                      controlStep={controlStep}
                      demandaData={demandaData}
                      changeDemandCodes={handleChangeDemandCode}
                    />
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <Registry
              typeIdentification={demandaData.tipo_identificacao}
              demandCode={demandCodes}
            />
          )}
        </>
      ) : (
        <Box margin="auto">
          <CircularProgress size={55} />
        </Box>
      )}
    </div>
  );
}
